body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.viz-wrapper {
  width: 100%;
  height: 100%;
  max-height: 900px;
}

svg {
  overflow: visible;
}

g.stackedChart > g.content > g.axis > g > line {
  opacity: 0;
}

svg > g.stackedChart > g.content > g.axis.axis--y > g:nth-child(3) > text {
  dominant-baseline: mathematical;
}

svg > g.stackedChart > g.content > g.axis.axis--y > g:nth-child(1) > text {
  dominant-baseline: ideographic;
}

g.axis > g > text {
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.axis.text {
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.legendEntry > svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.legendEntry {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.legendDiv {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.legendDiv.rows {
  flex-direction: column;
  align-items: flex-start;
}

.legendEntry.rows {
  margin-bottom: 6px;
}

.deselected > text {
  opacity: 0.2;
}
